export const brand = {
    logo: require("@/assets/img/logo.png"),
    lazylogo: require("@/assets/img/logo-lazy.png"),
    copywrite: "© " + (new Date().getFullYear()) + " Beeftech Labs S.R.L"
}

export const telegramEN = {
    label: "Telegram EN ",
    external: "https://t.me/partnerstaking",
    icon: require("@/assets/img/nav/telegram.png"),
}

export const telegramRO = {
    label: "Telegram RO",
    external: "https://t.me/partnerstakingro",
    icon: require("@/assets/img/nav/telegram.png"),
}

export const twitter = {
    label: "Twitter",
    external: "https://twitter.com/PartnerStaking",
    icon: require("@/assets/img/nav/twitter.png"),
}

export const email = {
    label: "Email",
    external: "mailto:support@partnerstaking.com",
    icon: require("@/assets/img/nav/email.png"),
}

export const faq = {
    label: "FAQ",
    route: "/faq",
    icon: require("@/assets/img/nav/faq.png"),
}

export const guides = {
    label: "Guides",
    route: "/guides",
    icon: require("@/assets/img/nav/guides.png"),
    enabled: false,
}

export const navigation = [
    {
        label: "Stake now",
        routes: [
            {
                label: "MultiversX",
                route: "/stake-egld",
                icon: require("@/assets/img/nav/multiversx.png"),
            },
            {
                label: "Zoidpay",
                route: "/stake-zpay",
                icon: require("@/assets/img/nav/zoidpay.png"),
            },
            {
                label: "Injective",
                route: "/stake-inj",
                icon: require("@/assets/img/nav/injective.png"),
            },
            {
                label: "Celestia",
                route: "/stake-tia",
                icon: require("@/assets/img/nav/celestia.svg"),
            },
            {
                label: "Realio",
                route: "/stake-rio",
                icon: require("@/assets/img/nav/realio.svg"),
            },
        ],
    },
    {
        label: "Contact us",
        routes: [ twitter, telegramEN, telegramRO, email ],
    },
    {
        label: "Support",
        routes: [ faq, guides ],
    },
]

export const footer = [
    {
        label: "Company",
        routes: [
            {
                label: "About us",
                route: "/#about-us",
            },
            {
                label: "Terms & Conditions",
                route: "/terms",
            },
            {
                label: "Privacy Policy",
                route: "/policy",
            },
        ],
    },
    {
        label: "Support",
        routes: [ faq, guides ],
    },
    {
        label: "Connect",
        routes: [ twitter, telegramEN, telegramRO, email ],
    },
]

export const faqs = [
    {
        label: "General",
        questions: [
            {
                title: "What is Staking?",
                description: "Staking is an activity where users lock or hold their funds in a cryptocurrency wallet to participate in maintaining the operations of a Proof-of-Stake (PoS)-based blockchain system. It is similar to crypto mining in the sense that it helps a network achieve consensus while rewarding users who participate.",
                topics: ['all'],
            },
            {
                title: "What is Delegating?",
                description: "A Staking Agency collects funds from multiple users, that cannot Stake themselves, because of the technical difficulties it involves and also because of the minimum amount usually required for Staking (e.g. 2500 EGLD for MultiversX). This method, called Delegation, allows users to join forces and participate in network consensus and security.",
                topics: ['all'],
            },
            {
                title: "Why should I Delegate?",
                description: "Delegation and Staking produce rewards that the blockchain offers in return for keeping the network secure.",
                topics: ['all'],
            },
            {
                title: "What if I want to Undelegate?",
                description: "You can Undelegate at any time, but you will only be able to withdraw your funds after an unbonding period. In order for Staking Agencies to keep the network secure by deploying validators, we need a certain stability concerning staked funds, that's why the waiting time period is imposed.",
                topics: ['all'],
            },
        ]
    },
    {
        label: "Numbers",
        questions: [
            {
                title: "What's the service fee on MultiversX?",
                description: "Our service fee is 16%, deducted from the rewards the MultiversX network provides you with.",
                topics: ['MultiversX'],
            },
            {
                title: "What is the base APR I can expect from Delegating on MultiversX?",
                description: "The MultiversX network awards an APR of up to 9% in case there is no network top-up (every node is staked with 2500 EGLD). By substracting our service fee, the resulting APR becomes around 7.5%.",
                topics: ['MultiversX'],
            },
            {
                title: "Can the APR change on MultiversX?",
                description: "Yes, it can increase or decrease, depending on the number of activate validators and network top-up. If the number of active validators drops below 3200, then the APR will increase. If all 3200 nodes are staked with more than 2500 EGLD (top-up), then the APR will start decreasing.",
                topics: ['MultiversX'],
            },
            {
                title: "How does top-up affect me on MultiversX?",
                description: "The top-up can affect your APR because any amount that surpasses the 2500 EGLD staked on a node don't produce the same amount of rewards as the base 2500 EGLD (the reward increase isn't proportional). While the overall network top-up affects any delegator (regardless of their staking agency of choice), it's nothing compared to how the top-up inside a specific agency affects its own delegators. Therefore Partner Staking has decided to limit the possible top-up at around 10%. We will be revisiting this decision only if the outcome doesn't affect our clients.",
                topics: ['MultiversX'],
            },
            {
                title: "What's the minimum delegation value on MultiversX?",
                description: "You can delegate a minimum of 1 EGLD.",
                topics: ['MultiversX'],
            },
            {
                title: "How long is the unbonding period on MultiversX?",
                description: "You can Undelegate at any time, but you will only be able to withdraw your funds after an unbonding period of 10 days.",
                topics: ['MultiversX'],
            },
            {
                title: "What's the minimum delegation value on ZoidPay?",
                description: "You can delegate a minimum of 1 ZPAY.",
                topics: ['ZoidPay'],
            },
            {
                title: "How long is the unbonding period on ZoidPay?",
                description: "When staking ZPAY in the Partner Staking Shopping Pool you need to choose a period of either 1, 3, 6 or 12 months. You ZPAY will be locked corresponding to this period and cannot unbond until it expires.",
                topics: ['ZoidPay'],
            },
            {
                title: "What is the base APR I can expect from Delegating on ZoidPay?",
                description: "APRs differ based on the locking period you choose when staking. You can earn an APR of 5% when locking for 1 month, 7% when locking 3 months, 9% when locking for 6 months, or 12% when locking for 12 months.",
                topics: ['ZoidPay'],
            },
            {
                title: "What's the service fee on Injective?",
                description: "Our commission rate is 5%, deducted from the rewards the Injective network provides you with.",
                topics: ['Injective'],
            },
            {
                title: "What is the base APR I can expect from Delegating on Injective?",
                description: "You can choose to stake your INJ on Injective Hub and enjoy an APR of 15%, or you can use the REStake app and benefit from auto-compounding rewards, thus increasing your APR to 17%.",
                topics: ['Injective'],
            },
            {
                title: "What's the minimum delegation value on Injective?",
                description: "There's no minimum delegation value, you can start any time.",
                topics: ['Injective'],
            },
            {
                title: "How long is the unbonding period on Injective?",
                description: "You can Undelegate at any time, but you will only be able to withdraw your funds after an unbonding period of 21 days.",
                topics: ['Injective'],
            },
            {
                title: "What's the service fee on Celestia?",
                description: "Our commission rate is 5%, deducted from the rewards the Celestia network provides you with.",
                topics: ['Celestia'],
            },
            {
                title: "What is the base APR I can expect from Delegating on Celestia?",
                description: "You can choose to stake your TIA using the Keplr Dashboard and enjoy an APR of 17.5%",
                topics: ['Celestia'],
            },
            {
                title: "What's the minimum delegation value on Celestia?",
                description: "There's no minimum delegation value, you can start any time.",
                topics: ['Celestia'],
            },
            {
                title: "How long is the unbonding period on Celestia?",
                description: "You can Undelegate at any time, but you will only be able to withdraw your funds after an unbonding period of 21 days.",
                topics: ['Celestia'],
            },
            {
                title: "What's the service fee on Realio?",
                description: "Our commission rate is 5%, deducted from the rewards the Realio network provides you with.",
                topics: ['Realio'],
            },
            {
                title: "What is the base APR I can expect from Delegating on Realio?",
                description: "You can choose to stake your RIO using the REStake app and benefit from auto-compounding rewards, and enjoy an APR of 5.3%",
                topics: ['Realio'],
            },
            {
                title: "What's the minimum delegation value on Realio?",
                description: "You can delegate a minimum of 1 RIO.",
                topics: ['Realio'],
            },
        ]
    },
    {
        label: "Mobile Apps",
        questions: [
            {
                title: "Is the mobile app available on the Google Play Store?",
                description: 'Yes, you can download it for free from <a href="https://play.google.com/store/apps/details?id=dev.peppark.partnerstaking.android&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" target="_blank">here</a>.',
                topics: ['all'],
            },
            {
                title: "Is the mobile app available on the Apple App Store?",
                description: 'Yes, you can download it for free from <a href="https://apps.apple.com/us/app/partner-staking/id1558363331" target="_blank">here</a>.',
                topics: ['all'],
            },
            {
                title: "Can I use the mobile app for delegating on MultiversX?",
                description: "Yes, simply connect using xPortal and go about managing your funds from the comfort of your phone.",
                topics: ['MultiversX'],
            },
            {
                title: "Can I use the mobile app for delegating in the Partner Staking Shopping Pool?",
                description: "Yes, simply connect using xPortal and go about managing your funds from the comfort of your phone.",
                topics: ['ZoidPay'],
            },
            {
                title: "Can I use the mobile app for delegating on Injective?",
                description: "Currently not, but we are actively working on updating the Partner Staking mobile app to ease your fund management from the comfort of your phone.",
                topics: ['Injective'],
            },
            {
                title: "Can I use the mobile app for delegating on Celestia?",
                description: "Currently not, but we are actively working on updating the Partner Staking mobile app to ease your fund management from the comfort of your phone.",
                topics: ['Celestia'],
            },
            {
                title: "Can I use the mobile app for delegating on Realio?",
                description: "Currently not, but we are actively working on updating the Partner Staking mobile app to ease your fund management from the comfort of your phone.",
                topics: ['Realio'],
            },
        ]
    },
    {
        label: "Security",
        questions: [
            {
                title: "Is my account safe?",
                description: "We never have any access to your credentials. If you use your JSON or PEM file, we redirect you to your Wallet, where you authenticate securely, without giving us any data.",
                topics: ['all'],
            },
            {
                title: "Are my funds safe?",
                description: "We use the delegation facilities provided by each blockchain, which deploy Smart Contracts to which you actually delegate your funds. Whatever operation you choose (Delegate, Undelegate, Claim Rewards, Re-delegate), the Smart Contracts execute it.",
                topics: ['all'],
            },
            {
                title: "Are there any risks?",
                description: "Delegation is non-custodial, which means we have no control (we cannot transfer them) and no way to manipulate your funds, since all of the operations are executed through Smart Contracts. The only thing we can do is stake them on our validator nodes. The funds remain securely in your wallet at all times.",
                topics: ['all'],
            },
        ]
    },
]

const filterFaqs = (name) => {
    let questions = faqs.map((category) => { return category.questions; }).flat();
    return [{
        label: "FAQ",
        questions: questions.filter((question) => { return question.topics.includes(name) }),
    }];
}

export const MultiversXData = {
    name: "MultiversX",
    logo: require("@/assets/img/home/networks/multiversx.png"),
    description: "MultiversX is a distributed Layer-1 blockchain network, highly scalable through sharding, secure and decentralized via 3000+ nodes, created to enable radically new applications, for users, businesses, society, and the new metaverse frontier. The eGold ($EGLD) Token is the native token powering the MultiversX network comprising all core network functionalities, such as staking, governance, transactions, smart contracts, and validator rewards.",
    instructions: "We offer you a wide range of tools for delegating and managing your $EGLD on MultiversX Staking. Whether you're at home on your laptop, or on the move, you have a choice between our Web Dashboard or our Mobile Apps. Staking has never been easier, anytime, anywhere.",
    primary: {
        "label": "Dashboard",
        "link": "https://mvx.partnerstaking.com/",
    },
    secondary: {
        "label": "Mobile App",
        "link": "/#mobile-apps",
    },
    coin: "elrond-erd-2",
    faqs: filterFaqs("MultiversX"),
};

export const ZPayData = {
    name: "Zoidpay",
    logo: require("@/assets/img/home/networks/zoidpay.png"),
    description: "ZoidPay is a crypto liquidity platform enabling instant card issuance for purchases at any merchant & driving adoption to web 3.0 financial services. ZoidPay was established in 2018 as a blockchain company focused on making crypto payments easy for everyone, driving adoption to web 3.0 financial services. The ZoidPay ecosystem offers a range of staking options for users looking to earn passive income and maximize their returns, powered by the $ZPAY token.",
    instructions: "One of the primary staking options offered by ZoidPay is the ability to stake funds in the Shopping Pools. To delegate your $ZPAY to the Partner Staking Shopping Pool, you can either use ZoidPay's website or the Partner Staking mobile app.",
    primary: {
        "label": "Shopping Pool",
        "link": "https://stake.zoidpay.com/staking/?sp=MW1ybnltbmptZHQ0cnNzdmowNmRqemRja3UwanU2ZW1sNmN0dWwwdHd1d21lajVhbjAzNHFoNGRkc3c=",
    },
    secondary: {
        "label": "Mobile App",
        "link": "/#mobile-apps",
    },
    coin: "zoid-pay",
    faqs: filterFaqs("ZoidPay"),
};

export const InjectiveData = {
    name: "Injective",
    logo: require("@/assets/img/home/networks/injective.png"),
    description: "Injective is an open, interoperable Layer-1 blockchain powering next-generation DeFi applications, including decentralized spot and derivatives exchanges, prediction markets, lending protocols, and more. Injective is built with the Cosmos SDK and leverages a Tendermint-based Proof-of-Stake (PoS) consensus mechanism, which allows it to achieve instant transaction finality while sustaining lightning-fast speeds (10,000+ TPS). The blockchain is community-driven, governed through its native token, $INJ, allowing anyone to access the full potential of its platform.",
    instructions: "You have two choices for delegating your $INJ with Partner Staking: you can either stake with us on Injective Hub and enjoy an APR of 15%, or you can stake with us using the REStake app and we will auto-compound your staking rewards for you, increasing your APR to 17%.",
    primary: {
        "label": "Injective Hub",
        "link": "https://hub.injective.network/validators/injvaloper1hkjsxadg0a0dj5pwdyfnf8qm0az6wcxcwhdnry/",
    },
    secondary: {
        "label": "REStake app",
        "link": "https://restake.app/injective/injvaloper1hkjsxadg0a0dj5pwdyfnf8qm0az6wcxcwhdnry/stake",
    },
    coin: "injective-protocol",
    faqs: filterFaqs("Injective"),
};

export const EthereumData = {
    name: "Ethereum",
    logo: require("@/assets/img/home/networks/ethereum.png"),
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. ",
    instructions: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate.",
    primary: "",
    secondary: "",
    coin: "ethereum",   
    faqs: filterFaqs("Ethereum"),
}

export const CelestiaData = {
    name: "Celestia",
    logo: require("@/assets/img/home/networks/celestia.png"),
    description: "Celestia is a modular data availability network and it enables the next generation of scalable blockchain architectures - modular blockchains. The network scales by decoupling execution from consensus and by introducing a new primitive, namely data availability sampling. Celestia is a permissionless network built with Cosmos SDK, uses proof-of-stake to secure its consensus, and is governed through its native token $TIA which is used for powering data availability.",
    instructions: "You can easily stake or redelegate your $TIA with Partner Staking by connecting your wallet in the Keplr Dashboard to enjoy an APR of 17.5%.",
    primary: {
        "label": "Keplr Dashboard",
        "link": "https://wallet.keplr.app/chains/celestia?modal=validator&chain=celestia&validator_address=celestiavaloper1clf3nqp89h97umhl4fmcqr642jz6rszcxegjc6",
    },
    secondary: "",
    coin: "celestia",
    faqs: filterFaqs("Celestia"),
};

export const RealioData = {
    name: "Realio",
    logo: require("@/assets/img/home/networks/realio.png"),
    description: "The Realio Network is an interoperable Layer-1 multi-chain Web3 ecosystem built using the Cosmos SDK, and focused on the issuance and management of digitally native and real-world assets across many compatible chains, both EVM and non-EVM. Realio is designed as an end-to-end, blockchain-based SaaS platform for the issuance, investment, and life-cycle management of digital securities and crypto assets. The Realio Network is governed through its native gas and utility token, namely $RIO, a multi-chain asset that currently exists on the Realio, BNB, Ethereum, Algorand and Stellar networks. ",
    instructions: "You can easily stake your $RIO with us using the REStake app and we will auto-compound your staking rewards for you, increasing your APR to 5%.",
    primary: {
        "label": "REStake app",
        "link": "https://restake.app/realio/realiovaloper196myd09jpcw58nmcntd0ug04m8fh20kvf7mxws",
    },
    secondary: "",
    coin: "realio-network",
    faqs: filterFaqs("Realio"),
};

export const homeData = {
    banner: require("@/assets/img/home/banner.jpg"),
    headline: {
        title: "Easiest way to stake your tokens",
        subtitle: "You just delegate, the rest, we create"
    },
    section1: {
        title: "Discover the networks",
        subtitle: "We're always on the lookout for the best staking opportunities out there, so stay tuned and check our selection",
        networks: [
            {
                name: MultiversXData.name,
                logo: MultiversXData.logo,
                stats: "Stake your EGLD",
                route: "stake-egld",
                enabled: true,
            },
            {
                name: ZPayData.name,
                logo: ZPayData.logo,
                stats: "Stake your ZPAY",
                route: "stake-zpay",
                enabled: true,
            },
            {
                name: InjectiveData.name,
                logo: InjectiveData.logo,
                stats: "Stake your INJ",
                route: "stake-inj",
                enabled: true,
            },
            {
                name: CelestiaData.name,
                logo: CelestiaData.logo,
                stats: "Stake your TIA",
                route: "stake-tia",
                enabled: true,
            },
            {
                name: RealioData.name,
                logo: RealioData.logo,
                stats: "Stake your RIO",
                route: "stake-rio",
            },
        ],
    },
    section2: {
        benefits: [
            {
                label: "User friendly & intuitive",
                icon: require("@/assets/img/home/benefits/friendly.png")
            },
            {
                label: "Always evolving",
                icon: require("@/assets/img/home/benefits/evolving.png")
            },
            {
                label: "Offers great insight into your funds' performance",
                icon: require("@/assets/img/home/benefits/insights.png")
            },
            {
                label: "Offers useful predictions and estimations",
                icon: require("@/assets/img/home/benefits/predictions.png")
            },
        ]
    },
    section3: {
        title: "About us",
        image: require("@/assets/img/home/about/illustration@2x.png"),
        paragraphs: [
            "Our founders here at Partner Staking are a unique mix of Software Engineers, Architects and well established Entrepreneurs with a vast expertise in financial investments. We started this project as a way to manage our own cryptocurrency investments, but soon realised that it would benefit other crypto enthusiasts as well.",
            "This is what makes us special. As well as being the people behind the platform, we are also directly affected by it because we stake our own funds, so we strive to develop the best technology for you, as well as for us. That's why if you stake with us, we're partners in the process.",
            "We realised that a visual representation for people to follow their investments would make everything easier to keep track of, so our platform analyzes data and builds meaningful statistics that enable you to predict the progress of your funds.",
            "Looking to the future, we developed mobile apps (Android and iOS) that bring all the necessary information and actions at your fingertips, as you're more likely at any time of day to be holding a mobile device, rather than to be at your computer. The mobile apps will evolve and expand over time to include all the features we're looking forward to build for you.",
        ]
    },
    section4: {
        title: "Why stake with us",
        subtitle: "Your stake and our tech knowledge makes for a great partnership",
        outcomes: [
            {
                icon: require("@/assets/img/home/outcomes/security.png"),
                title: "Security",
                description: "Multiple layers of security measures taken to make sure our hardware cannot be accessed or attacked in any way."
            },
            {
                icon: require("@/assets/img/home/outcomes/dashboard.png"),
                title: "Real-time Dashboard",
                description: "State of the art management of your funds allows you to execute any transaction making sure you're always in control."
            },
            {
                icon: require("@/assets/img/home/outcomes/savings.png"),
                title: "Minimal fees",
                description: "Our small service fee allows you to retain almost all of the delegation rewards."
            },
            {
                icon: require("@/assets/img/home/outcomes/performance.png"),
                title: "Performance",
                description: "Our datacenters rely on bare metal processing power, we will not risk your rewards by sharing hardware resources.",
            },
            {
                icon: require("@/assets/img/home/outcomes/contribute.png"),
                title: "Contributing",
                description: "We give back to the community. Our team of software engineers contributes to open source projects.",
            },
            {
                icon: require("@/assets/img/home/outcomes/mobile.png"),
                title: "Mobile Apps",
                description: "Our mobile apps ensure you have access to your funds on the go. We empower you to always be in control."
            },
        ],
    },
    section5: {
        title: "Smart tools on the go",
        subtitle: "We are commited to bringing you the best experience on any device. You can manage your staked funds on the go. From integrations with Maiar, to other interesting features, a lot more to come in the near future. Stay tuned!",
        image: require("@/assets/img/home/app/phone.png"),
        paragraphs: [
            "With WalletConnect integration we don't have access to your credentials or your account",
            "Customize your experience with Widgets and Notifications",
        ],
    },
};

export const privacyPolicy = `
<p><span>partnerstaking.com</span> operates the <a href="https://partnerstaking.com/" target="_blank">https://partnerstaking.com</a> website and Partner Staking app, which
provides the SERVICE.</p>
<p>This page is used to inform website visitors regarding our policies with the collection, use, and disclosure of
Personal Information if anyone decided to use our Service, the partnerstaking.com website.</p>
<p>If you choose to use our Service, then you agree to the collection and use of information in relation with this
policy. The Personal Information that we collect are used for providing and improving the Service. We will not
use or share your information with anyone except as described in this Privacy Policy. Our Privacy Policy was
created with the help of the <a href="https://www.privacypolicytemplate.net/" target="_blank">Privacy Policy Template</a> and
the <a href="https://www.generateprivacypolicy.com/" target="_blank">Privacy Policy Generator</a>.</p>
<p>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at
<a href="https://partnerstaking.com/" target="_blank">https://partnerstaking.com</a> , unless
otherwise defined in this Privacy Policy.</p>
<h1>Information Collection and Use</h1>
<p>For a better experience while using our Service, we may require you to provide us with certain personally
identifiable information, including but not limited to your name, phone number, and postal address. The
information that we collect will be used to contact or identify you.</p>
<h1>Log Data</h1>
<p>We want to inform you that whenever you visit our Service, we collect information that your browser sends to us that
is called Log Data. This Log Data may include information such as your computer’s Internet Protocol ("IP")
address, browser version, pages of our Service that you visit, the time and date of your visit, the time spent
on those pages, and other statistics.</p>
<h1>Cookies</h1>
<p>Cookies are files with small amount of data that is commonly used an anonymous unique identifier. These are sent to
your browser from the website that you visit and are stored on your computer’s hard drive.</p>
<p>Our website uses these "cookies" to collection information and to improve our Service. You have the option to either
accept or refuse these cookies, and know when a cookie is being sent to your computer. If you choose to refuse
our cookies, you may not be able to use some portions of our Service.</p>
<p>For more general information on cookies, please read <a
        href="https://www.privacypolicyonline.com/what-are-cookies/" target="_blank">"What Are Cookies"</a>.</p>
<h1>Service Providers</h1>
<p>We may employ third-party companies and individuals due to the following reasons:</p>
<ul>
<li>
        <p>To facilitate our Service;</p>
</li>
<li>
        <p>To provide the Service on our behalf;</p>
</li>
<li>
        <p>To perform Service-related services; or</p>
</li>
<li>
        <p>To assist us in analyzing how our Service is used.</p>
</li>
</ul>
<p>We want to inform our Service users that these third parties have access to your Personal Information. The reason is
to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the
information for any other purpose.</p>
<h1>Security</h1>
<p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable
means of protecting it. But remember that no method of transmission over the internet, or method of electronic
storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>
<h1>Links to Other Sites</h1>
<p>Our Service may contain links to other sites. If you click on a third-party link, you will be directed to that site.
Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy
Policy of these websites. We have no control over, and assume no responsibility for the content, privacy
policies, or practices of any third-party sites or services.</p>
<h1>Children's Privacy</h1>
<p>Our Services do not address anyone under the age of 13. We do not knowingly collect personal identifiable information
from children under 13. In the case we discover that a child under 13 has provided us with personal information,
we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child
has provided us with personal information, please contact us so that we will be able to do necessary actions.
</p>
<h1>Changes to This Privacy Policy</h1>
<p>We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any
changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are
effective immediately, after they are posted on this page.</p>
<h1>Contact Us</h1>
<p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at <a
        href="mailto:support@partnerstaking.com" target="_blank">support@partnerstaking.com</a>.</p>
`

export const termsAndConditions = `
<p>Please read these terms and conditions carefully before using Our Service.</p>
<h1>Interpretation</h1>
<p>The words of which the initial letter is capitalized have meanings defined under the following conditions. The
        following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
</p>
<h1>Definitions</h1>
<p>For the purposes of these Terms and Conditions:</p>
<ul>
        <li>
                <p><strong>Affiliate</strong> means an entity that controls, is controlled by or is under common control
                        with a party, where "control" means ownership of 50% or more of the shares, equity interest or
                        other securities entitled to vote for election of directors or other managing authority.</p>
        </li>
        <li>
                <p><strong>Country</strong> refers to: Romania</p>
        </li>
        <li>
                <p><strong>Company</strong> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement)
                        refers to Partner Staking.</p>
        </li>
        <li>
                <p><strong>Device</strong> means any device that can access the Service such as a computer, a cellphone
                        or a digital tablet.</p>
        </li>
        <li>
                <p><strong>Service</strong> refers to the Website.</p>
        </li>
        <li>
                <p><strong>Terms and Conditions</strong> (also referred as "Terms") mean these Terms and Conditions that
                        form the entire agreement between You and the Company regarding the use of the Service. This
                        Terms and Conditions agreement has been created with the help of the <a
                                href="https://www.termsfeed.com/terms-conditions-generator/" target="_blank">Terms and
                                Conditions
                                Generator</a>.</p>
        </li>
        <li>
                <p><strong>Third-party Social Media Service</strong> means any services or content (including data,
                        information, products or services) provided by a third-party that may be displayed, included or
                        made available by the Service.</p>
        </li>
        <li>
                <p><strong>Website</strong> refers to Partner Staking, accessible from <a
                                href="https://partnerstaking.com/" target="_blank">https://partnerstaking.com</a></p>
        </li>
        <li>
                <p><strong>You</strong> means the individual accessing or using the Service, or the company, or other
                        legal entity on behalf of which such individual is accessing or using the Service, as
                        applicable.</p>
        </li>
</ul>
<h1>Acknowledgment</h1>
<p>These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and
        the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the
        Service.</p>
<p>Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and
        Conditions. These Terms and Conditions apply to all visitors, users and others who access or use the Service.
</p>
<p>By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part
        of these Terms and Conditions then You may not access the Service.</p>
<p>You represent that you are over the age of 18. The Company does not permit those under 18 to use the Service.</p>
<p>Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy
        Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and
        disclosure of Your personal information when You use the Application or the Website and tells You about Your
        privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.
</p>
<h1>Links to Other Websites</h1>
<p>Our Service may contain links to third-party web sites or services that are not owned or controlled by the Company.
</p>
<p>The Company has no control over, and assumes no responsibility for, the content, privacy policies, or practices of
        any third party web sites or services. You further acknowledge and agree that the Company shall not be
        responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in
        connection with the use of or reliance on any such content, goods or services available on or through any such
        web sites or services.</p>
<p>We strongly advise You to read the terms and conditions and privacy policies of any third-party web sites or services
        that You visit.</p>
<h1>Termination</h1>
<p>We may terminate or suspend Your access immediately, without prior notice or liability, for any reason whatsoever,
        including without limitation if You breach these Terms and Conditions.</p>
<p>Upon termination, Your right to use the Service will cease immediately.</p>
<h1>Limitation of Liability</h1>
<p>Notwithstanding any damages that You might incur, the entire liability of the Company and any of its suppliers under
        any provision of this Terms and Your exclusive remedy for all of the foregoing shall be limited to the amount
        actually paid by You through the Service or 100 USD if You haven't purchased anything through the Service.</p>
<p>To the maximum extent permitted by applicable law, in no event shall the Company or its suppliers be liable for any
        special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for
        loss of profits, loss of data or other information, for business interruption, for personal injury, loss of
        privacy arising out of or in any way related to the use of or inability to use the Service, third-party software
        and/or third-party hardware used with the Service, or otherwise in connection with any provision of this Terms),
        even if the Company or any supplier has been advised of the possibility of such damages and even if the remedy
        fails of its essential purpose.</p>
<p>Some states do not allow the exclusion of implied warranties or limitation of liability for incidental or
        consequential damages, which means that some of the above limitations may not apply. In these states, each
        party's liability will be limited to the greatest extent permitted by law.</p>
<h1>"AS IS" and "AS AVAILABLE" Disclaimer</h1>
<p>The Service is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any
        kind. To the maximum extent permitted under applicable law, the Company, on its own behalf and on behalf of its
        Affiliates and its and their respective licensors and service providers, expressly disclaims all warranties,
        whether express, implied, statutory or otherwise, with respect to the Service, including all implied warranties
        of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise
        out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing,
        the Company provides no warranty or undertaking, and makes no representation of any kind that the Service will
        meet Your requirements, achieve any intended results, be compatible or work with any other software,
        applications, systems or services, operate without interruption, meet any performance or reliability standards
        or be error free or that any errors or defects can or will be corrected.</p>
<p>Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or
        warranty of any kind, express or implied: (i) as to the operation or availability of the Service, or the
        information, content, and materials or products included thereon; (ii) that the Service will be uninterrupted or
        error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through
        the Service; or (iv) that the Service, its servers, the content, or e-mails sent from or on behalf of the
        Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.</p>
<p>Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory
        rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a
        case the exclusions and limitations set forth in this section shall be applied to the greatest extent
        enforceable under applicable law.</p>
<h1>Loyal Client Program</h1>
<p>You are entitled (by request) to a lower Service Fee depending on the value staked with Partner Staking, expressed in
        EGLD, as follows:</p>
<ul>
        <li>
                <p>At least 10.000 EGLD staked - 25% Service Fee discount</p>
        </li>
        <li>
                <p>At least 50.000 EGLD staked - 50% Service Fee discount</p>
        </li>
</ul>
<p><span>If you are eligible for the Loyal Client Program, you may request the Service Fee discount by sending
                        an
                        email to </strong><a href="mailto:contact@partnerstaking.com"
                        target="_blank">contact@partnerstaking.com</strong></a></span></p>
<h1>Governing Law</h1>
<p>The laws of the Country, excluding its conflicts of law rules, shall govern this Terms and Your use of the Service.
        Your use of the Application may also be subject to other local, state, national, or international laws.</p>
<h1>Disputes Resolution</h1>
<p>If You have any concern or dispute about the Service, You agree to first try to resolve the dispute informally by
        contacting the Company.</p>
<h5>For European Union (EU) Users</h5>
<p>If You are a European Union consumer, you will benefit from any mandatory provisions of the law of the country in
        which you are resident in.</p>
<h5>United States Legal Compliance</h5>
<p>You represent and warrant that (i) You are not located in a country that is subject to the United States government
        embargo, or that has been designated by the United States government as a "terrorist supporting" country, and
        (ii) You are not listed on any United States government list of prohibited or restricted parties.</p>
<h1>Severability</h1>
<p>If any provision of these Terms is held to be unenforceable or invalid, such provision will be changed and
        interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law
        and the remaining provisions will continue in full force and effect.</p>
<h1>Waiver</h1>
<p>Except as provided herein, the failure to exercise a right or to require performance of an obligation under this
        Terms shall not effect a party's ability to exercise such right or require such performance at any time
        thereafter nor shall be the waiver of a breach constitute a waiver of any subsequent breach.</p>
<h1>Translation Interpretation</h1>
<p>These Terms and Conditions may have been translated if We have made them available to You on our Service. You agree
        that the original English text shall prevail in the case of a dispute.</p>
<h1>Changes to These Terms and Conditions</h1>
<p>We reserve the right, at Our sole discretion, to modify or replace these Terms at any time. If a revision is material
        We will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. What
        constitutes a material change will be determined at Our sole discretion.</p>
<p>By continuing to access or use Our Service after those revisions become effective, You agree to be bound by the
        revised terms. If You do not agree to the new terms, in whole or in part, please stop using the website and the
        Service.</p>
<h1>Contact Us</h1>
<p>If you have any questions about these Terms and Conditions, You can contact us:</p>
<ul>
        <li>
                <p>By email: <a href="mailto:support@partnerstaking.com" target="_blank">support@partnerstaking.com</a>
                </p>
        </li>
</ul>
`